import { createSlice } from '@reduxjs/toolkit'

const bannerHeaderSlice = createSlice({
  name: 'bannerHeader',
  initialState: {
    bannerDetail: [],
  },
  reducers: {
    setBanner: (state, action) => {
      state.bannerDetail = action.payload
    },
  },
})
export const { setBanner } = bannerHeaderSlice.actions
export default bannerHeaderSlice.reducer
