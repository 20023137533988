export const external_columns = [
  'calculatedSetupStatus',
  'tcin',
  'dpci',
  'pyramid',
  'division',
  'departmentId',
  'departmentName',
  'classId',
  'className',
  'merchandiseTypeName',
  'itemTypeName',
  'legacyCreateType',
  'productTitle',
  'vendorDescription',
  'POS Description',
  'Shelf Description',
  'shortDescription',
  'launchPadCreatedTimestamp',
  'launchPadVendorComplete',
  'launchPadComplete',
  'imnLifecycleStatus',
  'imnCreateTimestamp',
  'mtaCompletionDate',
  'launchDate',
  'streetDate',
  'Target Street Date & Time',
  'assortmentType',
  'intendedSellingChannels',
  'vendor',
  'allBarcodes',
  'allManufacturerStyleNumber',
  'allBrands',
  'complianceReady',
  'Recall Indicator',
  'hazmatBlockReasons',
  'hazmatPublish',
  'importApprovalStatus',
  'relationshipType',
  'variationParent',
  'collectionParent',
  'bundleMaster',
  'dynamicAssortmentMaster',
  'onlineLocationStatus',
  'itemCost',
  'hasImage', // 'regularRetail3991',
  // 'Sample Status',
  // 'Sample Due Date',
  // 'Sample Received Date',
  'merchantPublishFlag',
  'hasInventory',
  'longCopy',
  'softBullets',
  // 'Task Status',
  'mostRecentComment',
  // 'IDS',
  'assortmentPlanName',
  'commitmentDate',
  'parentTcin',
  'groupId',
]
