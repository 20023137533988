import { useMemo, useState, useCallback, useEffect } from 'react'
import { CustomStatsToolPanel } from './customStatsToolPanel'
import { useQuery, useMutation } from '@apollo/client'
import { setSearchText } from '../../../store/userSearchProfile'
import {
  getSearchCopyLinkQuery,
  setSearchCopyLinkMutation,
} from '../../../apiItemGraphql/userSearch'
import { useToaster } from '@enterprise-ui/canvas-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { updateFilters } from '../../../store/userSearchProfile'
import { omitTypenameDeep } from '../../../globalUtils/commonUtills'
import { useFirefly } from '../../../globalUtils/useAnalytics'
import { FIREFLY_EVENTS } from '../../../constants/search'

export const useGridHeader = (props) => {
  const { handleTrackCustomEvent } = useFirefly()
  const [openCustomiseColumns, setOpenCustomiseColumns] = useState(true)
  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Customize Table Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: CustomStatsToolPanel,
          minWidth: 315,
          width: 315,
          maxWidth: 315,
        },
      ],
      position: 'right',
      defaultToolPanel: 'filters',
      hiddenByDefault: true,
    }
  }, [])

  const { searchText, filtersApplied } = useSelector((state) => {
    return {
      searchText: state.userSearchProfile.filters.searchText,
      filtersApplied: state.userSearchProfile.savedSearch.activeSavedFilters,
    }
  })

  const [setSearchCopyLink] = useMutation(setSearchCopyLinkMutation())
  const { protocol, hostname, port } = window.location
  const toaster = useToaster()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const viewId = searchParams.get('viewId')
  const { data: searchCopyId } = useQuery(getSearchCopyLinkQuery(), {
    skip: !viewId,
    variables: {
      viewId: viewId,
    },
  })
  const handleSetSearchText = useCallback(async () => {
    if (viewId && searchCopyId?.getSearchCopyLink) {
      if (searchCopyId.getSearchCopyLink.searchText) {
        dispatch(setSearchText(searchCopyId.getSearchCopyLink.searchText))
      }
      if (searchCopyId.getSearchCopyLink.filters) {
        const finalFilters = omitTypenameDeep(
          searchCopyId.getSearchCopyLink.filters,
        )
        dispatch(updateFilters(finalFilters))
      }
    }
  }, [viewId, searchCopyId, dispatch])

  useEffect(() => {
    handleSetSearchText()
  }, [handleSetSearchText])

  const handleCopyText = async () => {
    try {
      const {
        data: { setSearchCopyLink: viewId },
      } = await setSearchCopyLink({
        variables: {
          filters: filtersApplied,
          searchText: searchText,
        },
      })
      // const newCopyText = `${protocol}//${hostname}:${port}/search?viewId=${viewId.viewId}`
      const newCopyText =
        hostname === 'localhost'
          ? `${protocol}//${hostname}:${port}/search?viewId=${viewId.viewId}`
          : `${protocol}//${hostname}/search?viewId=${viewId.viewId}`
      navigator.clipboard.writeText(newCopyText).then(() => {
        toaster({
          type: 'success',
          heading: 'Link copied!',
          message: newCopyText,
        })
      })
      //event capture
      handleTrackCustomEvent(
        FIREFLY_EVENTS.EVENT_TYPE.GRID_ACTION,
        FIREFLY_EVENTS.CUSTOM_INTERACTION_KEYS.COPY_LINK,
      )
    } catch (error) {
      toaster({
        type: 'error',
        heading: 'Error Copying Link',
        message: error.message,
      })
    }
  }
  return {
    openCustomiseColumns,
    setOpenCustomiseColumns,
    sideBar,
    handleCopyText,
  }
}
