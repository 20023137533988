import { useEffect, useRef } from 'react'
import { useMutation } from '@apollo/client'
import 'ag-grid-community/styles/ag-grid.css'
import { Button, Card, Input, useToaster } from '@enterprise-ui/canvas-ui-react'
import Sortable from 'sortablejs'
import EnterpriseIcon, { DragIcon } from '@enterprise-ui/icons'

import { setUserProfileColumnPreferenceApi } from '../../../apiItemGraphql/userProfile'

const useSortableContainerRefs = () => {
  const ref1 = useRef(null)
  const ref2 = useRef(null)
  return [ref1, ref2]
}

const ColumnItem = ({ column, type, handleVisibilityChange }) => (
  <div
    key={column.colId || column.field}
    className="ag-column-select-column move-column cursor-move"
    data-col-id={column.colId}
    aria-hidden="true"
  >
    <div className="">
      <Input.Checkbox
        className="ag-input-field-input ag-checkbox-input"
        id={`${column.colId}_${type}`}
        checked={!column.hide}
        onChange={() => handleVisibilityChange(column)}
        aria-label="Press SPACE to toggle visibility (visible)"
      />
    </div>
    <span className="ag-column-select-column-label">{column.headerName}</span>
    <EnterpriseIcon icon={DragIcon} className="cursor-move" />
  </div>
)

export const ColumnCard = (props) => {
  const {
    columns,
    myColumns,
    handleSelectAllClick,
    handleVisibilityChange,
    selectAllChecked,
    selectedColumns,
    additionalColumns,
    showAddMoreLabel,
    propsApi,
    setSelectedColumns,
    setAdditionalColumns,
  } = props

  const [columnPreference] = useMutation(setUserProfileColumnPreferenceApi())
  const sortableContainerRefs = useSortableContainerRefs()
  const makeToast = useToaster()

  useEffect(() => {
    const sortables = sortableContainerRefs.map((ref, index) => {
      if (ref.current) {
        return new Sortable(ref.current, {
          group: 'columns',
          handle: '.cursor-move',
          draggable: '.move-column',
          easing: 'cubic-bezier(1, 0, 0, 1)',
          onEnd: (evt) => {
            const { oldIndex, newIndex } = evt
            const isAdditional = index === 1
            const currentColumns = isAdditional
              ? [...additionalColumns]
              : [...selectedColumns]
            const [movedItem] = currentColumns.splice(oldIndex, 1)
            currentColumns.splice(newIndex, 0, movedItem)

            if (isAdditional) {
              setAdditionalColumns(currentColumns)
            } else {
              setSelectedColumns(currentColumns)
              // Apply the new column state to the ag-Grid
              const newColumnState = currentColumns.map((col) => ({
                colId: col.colId,
                hide: col.hide,
                width: col.width,
              }))
              propsApi.applyColumnState({
                state: newColumnState,
                applyOrder: true, // Important to reorder columns
              })
            }
          },
        })
      }
      return null
    })

    return () => sortables.forEach((sortable) => sortable && sortable.destroy())
  }, [
    selectedColumns,
    additionalColumns,
    propsApi,
    sortableContainerRefs,
    setSelectedColumns,
    setAdditionalColumns,
  ])

  const handleColumnSave = () => {
    const userProfileProperty = {
      parentKey: 'Search',
      propertyKey: 'columnPreference',
      value: [...selectedColumns, ...additionalColumns].map((col) => ({
        id: col.colId,
        width: col.width,
        visible: !col.hide || false,
      })),
    }
    columnPreference({
      variables: {
        userProfileProperty: [userProfileProperty],
      },
    })
      .then(() => {
        makeToast({
          type: 'success',
          heading: 'Success',
          message: 'Column Preference Saved Successfully',
        })
        props.refetch()
        propsApi.closeToolPanel()
      })
      .catch((error) => {
        makeToast({
          type: 'error',
          heading: 'Error',
          message: 'Error Adding Column Preferences',
        })
        propsApi.closeToolPanel()
      })
  }

  const type = myColumns ? 'myColumns' : 'default'

  return (
    <Card corners="none" elevation="0" className="tab-card">
      <p className="hc-mt-dense hc-mb-min hc-clr-grey01">Selected Columns</p>
      <Card corners="none" elevation="0" className="tab-container show-scroll">
        <Input.Checkbox
          className="ag-input-field-input ag-checkbox-input"
          id={`select-all-${type}`}
          checked={selectAllChecked}
          onChange={handleSelectAllClick}
          label="Select all"
        />
        <div ref={sortableContainerRefs[0]}>
          {myColumns
            ? selectedColumns.map((column) => (
                <ColumnItem
                  key={column.colId || column.field}
                  column={column}
                  type={type}
                  handleVisibilityChange={handleVisibilityChange}
                />
              ))
            : columns
                .filter((col) => !col.hide && col.field !== 'selection')
                .map((column) => (
                  <ColumnItem
                    key={column.colId || column.field}
                    column={column}
                    type={type}
                    handleVisibilityChange={handleVisibilityChange}
                  />
                ))}
        </div>
        {showAddMoreLabel === true && (
          <p className="hc-mt-dense hc-mb-min hc-clr-grey01">
            Add More Columns
          </p>
        )}
        <div ref={sortableContainerRefs[1]}>
          {myColumns
            ? additionalColumns.map((column) => (
                <ColumnItem
                  key={column.colId || column.field}
                  column={column}
                  type={type}
                  handleVisibilityChange={handleVisibilityChange}
                />
              ))
            : columns
                .filter((col) => col.hide)
                .map((column) => (
                  <ColumnItem
                    key={column.colId || column.field}
                    column={column}
                    type={type}
                    handleVisibilityChange={handleVisibilityChange}
                  />
                ))}
        </div>
      </Card>
      <div className="hc-mt-dense button-container">
        <Button
          onClick={() => propsApi.closeToolPanel()}
          className="hc-ta-right hc-mr-dense"
        >
          Cancel
        </Button>
        <Button
          onClick={myColumns ? handleColumnSave : () => {}}
          className="hc-ta-right"
          type="primary"
          disabled={!myColumns}
        >
          Save
        </Button>
      </div>
    </Card>
  )
}
