import { internal_columns } from './search-colunms-internal'
import { external_columns } from './search-colunms-external'

export const filterIds = [
  {
    tcin: 'tcin',
    dpci: 'dpci',
    barcode: 'barcode',
    vendor: 'vendor',
    imnComplete: 'imnComplete',
    launchPadVendorComplete: 'launchPadVendorComplete',
    launchPadComplete: 'launchPadComplete',
    itemCreateDateRelative: 'itemCreateDateRelative',
    merchandiseHierarchy: 'merchandiseHierarchy',
    launchDateRelative: 'launchDateRelative',
    launchDate: 'launchDate',
    launchPadCreatedTimestamp: 'launchPadCreatedTimestamp',
    itemSetupStatus: 'itemSetupStatus',
    partnersApproved: 'partnersApproved',
    hazmatPublished: 'hazmatPublished',
    hasPrimaryImage: 'hasPrimaryImage',
    importApprovalStatus: 'importApprovalStatus',
    hasRetailPrice: 'hasRetailPrice',
    merchantPublished: 'merchantPublished',
    hasDataQualityIssues: 'hasDataQualityIssues',
    commitmentDate: 'commitmentDate',
    commitmentDateRelative: 'commitmentDateRelative',
    launchPadReadyForMaintainer: 'launchPadReadyForMaintainer',
    assortmentPlanName: 'assortmentPlanName',
    manufacturerStyleNumber: 'manufacturerStyleNumber',
    itemLocationStatus: 'itemLocationStatus',
    merchItemType: 'merchItemType',
    productBrand: 'productBrand',
    pyramidDivision: 'pyramidDivision',
    relationshipType: 'relationshipType',
    maintenanceRequestDateRelative: 'maintenanceRequestDateRelative',
    maintenanceRequestType: 'maintenanceRequestType',
    maintenanceRequestTaskStatus: 'maintenanceRequestTaskStatus',
    maintenanceRequestUpdateUser: 'maintenanceRequestUpdateUser',
    maintenanceRequestRequestorUser: 'maintenanceRequestRequestorUser',
    legacyCreateType: 'legacyCreateType',
    launchPadStatus: 'launchPadStatus',
    assortmentType: 'assortmentType',
  },
]
export const searchColumns = [
  {
    id: 'calculatedSetupStatus',
    displayName: 'ISU Status',
    sortable: true,
    componentType: 'chipText',
    width: 200,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'tcin',
    displayName: 'TCIN',
    sortable: true,
    componentType: 'maintainLink',
    width: 120,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'dpci',
    displayName: 'DPCI',
    sortable: true,
    componentType: 'text',
    width: 142,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'pyramid',
    displayName: 'Pyramid',
    sortable: false,
    componentType: null,
    width: null,
    visible: true,
  },

  {
    id: 'division',
    displayName: 'Division',
    sortable: false,
    componentType: null,
    width: null,
    visible: true,
  },
  {
    id: 'departmentId',
    displayName: 'Department ID',
    sortable: true,
    componentType: 'text',
    width: 200,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'departmentName',
    displayName: 'Department Name',
    sortable: false,
    componentType: 'text',
    width: 200,
    visible: true,
  },
  {
    id: 'classId',
    displayName: 'Class ID',
    sortable: true,
    componentType: 'text',
    width: 200,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'className',
    displayName: 'Class Name',
    sortable: true,
    componentType: 'text',
    width: 200,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'merchandiseTypeName',
    displayName: 'Merchandise Type',
    sortable: true,
    componentType: 'text',
    width: 200,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'itemTypeName',
    displayName: 'Item Type',
    sortable: true,
    componentType: 'text',
    width: 150,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'legacyCreateType',
    displayName: 'Create Type',
    sortable: false,
    componentType: 'text',
    width: 200,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'productTitle',
    displayName: 'Product Title',
    sortable: false,
    componentType: 'text',
    width: 566,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'vendorDescription',
    displayName: 'Vendor Description',
    sortable: false,
    componentType: 'text',
    width: 416,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'shortDescription',
    displayName: 'Short Description',
    sortable: false,
    componentType: null,
    width: null,
    visible: true,
  },
  {
    id: 'launchPadCreatedTimestamp',
    displayName: 'LaunchPad Create Date',
    sortable: true,
    componentType: 'text',
    width: 198,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'launchPadVendorComplete',
    displayName: 'LaunchPad Vendor Complete',
    sortable: true,
    componentType: 'boolean',
    width: 200,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'launchPadComplete',
    displayName: 'LaunchPad Complete',
    sortable: true,
    componentType: 'boolean',
    width: 200,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'imnLifecycleStatus',
    displayName: 'IMN Lifecycle Status',
    sortable: true,
    componentType: 'text',
    width: 200,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'imnCreateTimestamp',
    displayName: 'IMN Create Date',
    sortable: true,
    componentType: 'text',
    width: 200,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'mtaCompletionDate',
    displayName: 'MTA Completion Date',
    sortable: true,
    componentType: 'text',
    width: 200,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'launchDate',
    displayName: 'Launch Date',
    sortable: true,
    componentType: 'text',
    width: 146,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'streetDate',
    displayName: 'Street Date',
    sortable: true,
    componentType: 'text',
    width: 200,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'assortmentType',
    displayName: 'Assortment Type',
    sortable: false,
    componentType: 'text',
    width: 200,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'intendedSellingChannels',
    displayName: 'Intended Selling Channels',
    sortable: false,
    componentType: 'text',
    width: 200,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'primaryVendorId',
    displayName: 'Primary 3991 Vendor ID',
    sortable: false,
    componentType: 'text',
    width: 169,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'primaryVendorName',
    displayName: 'Primary 3991 Vendor Name',
    sortable: true,
    componentType: 'text',
    width: 248,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'vendorDetails',
    displayName: 'Vendor Details',
    sortable: false,
    componentType: 'infoTable',
    width: 149,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'allBarcodes',
    displayName: 'Barcodes',
    sortable: false,
    componentType: null,
    width: null,
    visible: true,
  },
  {
    id: 'allManufacturerStyleNumber',
    displayName: 'Manufacturer Style Number',
    sortable: false,
    componentType: null,
    width: 250,
    visible: true,
  },
  {
    id: 'allBrands',
    displayName: 'Brands',
    sortable: false,
    componentType: null,
    width: null,
    visible: true,
  },
  {
    id: 'complianceReady',
    displayName: 'Compliance Ready',
    sortable: true,
    componentType: 'text',
    width: 200,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'hazmatBlockReasons',
    displayName: 'Hazmat Block Reasons',
    sortable: false,
    componentType: 'text',
    width: 200,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'hazmatPublish',
    displayName: 'Hazmat Publish',
    sortable: true,
    componentType: 'boolean',
    width: 200,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'importApprovalStatus',
    displayName: 'Import Approval Status',
    sortable: false,
    componentType: 'text',
    width: null,
    visible: true,
  },
  {
    id: 'relationshipType',
    displayName: 'Relationship Type',
    sortable: true,
    componentType: 'text',
    width: 200,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'groupId',
    displayName: 'Group ID',
    sortable: true,
    componentType: 'text',
    width: 200,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'parentTcin',
    displayName: 'Parent TCIN(s)',
    sortable: false,
    componentType: 'text',
    width: 200,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'variationParent',
    displayName: 'Variation Parent',
    sortable: false,
    componentType: null,
    width: null,
    visible: true,
  },
  {
    id: 'collectionParent',
    displayName: 'Collection Parent',
    sortable: false,
    componentType: null,
    width: null,
    visible: true,
  },
  {
    id: 'bundleMaster',
    displayName: 'Bundle Master',
    sortable: false,
    componentType: null,
    width: null,
    visible: true,
  },
  {
    id: 'dynamicAssortmentMaster',
    displayName: 'DA Master',
    sortable: false,
    componentType: null,
    width: null,
    visible: true,
  },
  {
    id: 'onlineLocationStatus',
    displayName: '3991 Item Loc Status',
    sortable: true,
    componentType: 'text',
    width: 200,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'itemCost',
    displayName: 'Primary 3991 Cost',
    sortable: true,
    componentType: 'text',
    width: 200,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'regularRetail3991',
    displayName: '3991 Current Retail',
    sortable: true,
    componentType: 'text',
    width: 200,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'merchantPublishFlag',
    displayName: 'Merchant Publish Flag',
    sortable: true,
    componentType: 'boolean',
    width: 200,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'hasImage',
    displayName: 'Has Primary Image',
    sortable: false,
    componentType: 'boolean',
    width: 200,
    visible: true,
  },
  {
    id: 'hasInventory',
    displayName: 'Inventory',
    sortable: true,
    componentType: 'boolean',
    width: 200,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'longCopy',
    displayName: 'Long Copy',
    sortable: false,
    componentType: 'text',
    width: 200,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'softBullets',
    displayName: 'Soft Bullets',
    sortable: false,
    componentType: 'text',
    width: 200,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'previewUrl',
    displayName: 'Target.com Preview URL',
    sortable: false,
    componentType: 'hyperLink',
    width: 200,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'primaryImageUrl',
    displayName: 'Image Url',
    sortable: false,
    componentType: 'thumbnail',
    width: 75,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  // {
  //   id: 'primaryBarcode',
  //   displayName: 'Primary 3991 Barcode',
  //   sortable: false,
  //   componentType: 'text',
  //   width: 192,
  //   visible: false,
  //   notUserVisible: null,
  //   __typename: 'SearchColumnRow',
  // },

  // {
  //   id: 'manufacturerStyleNumber',
  //   displayName: 'Primary 3991 Manufac Style #',
  //   sortable: true,
  //   componentType: 'text',
  //   width: 200,
  //   visible: false,
  //   notUserVisible: null,
  //   __typename: 'SearchColumnRow',
  // },
  {
    id: 'mostRecentComment',
    displayName: 'Comments',
    sortable: false,
    componentType: 'textInput',
    width: 200,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'assortmentPlanName',
    displayName: 'APEX Plan Name',
    sortable: true,
    componentType: 'text',
    width: 162,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'apexBuyerComments',
    displayName: 'APEX Buyer Comments',
    sortable: false,
    componentType: 'text',
    width: 200,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'apexCommitManagementSystemIdentification',
    displayName: 'APEX CMS ID',
    sortable: true,
    componentType: 'text',
    width: 120,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'apexProductTitleNotes',
    displayName: 'APEX Notes to Inform Product Title',
    sortable: false,
    componentType: 'text',
    width: 200,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'apexVendorEmail',
    displayName: 'APEX Vendor Contact Email',
    sortable: true,
    componentType: 'text',
    width: 120,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'commitmentDate',
    displayName: 'Commit Order Due Date',
    sortable: true,
    componentType: 'text',
    width: 199,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'itemDataQualityIssues',
    displayName: 'Data Quality Issues',
    sortable: false,
    componentType: 'infoTable',
    width: 175,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'isMaintainable',
    displayName: 'Is Maintainable',
    sortable: false,
    componentType: null,
    width: null,
    visible: true,
    notUserVisible: true,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'isMMBItem',
    displayName: 'MMB Item',
    sortable: false,
    componentType: null,
    width: null,
    visible: true,
  },
  {
    id: 'apexVendorName',
    displayName: 'APEX Vendor Name',
    sortable: true,
    componentType: 'text',
    width: 120,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'hasLongCopy',
    displayName: 'Has Long Copy',
    sortable: false,
    componentType: 'boolean',
    width: 200,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'variationColor',
    displayName: 'Variation Color',
    sortable: true,
    componentType: 'text',
    width: 200,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'variationSize',
    displayName: 'Variation Size',
    sortable: true,
    componentType: 'text',
    width: 200,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  // {
  //   id: 'productBrand',
  //   displayName: 'Primary Product Brand',
  //   sortable: false,
  //   componentType: 'text',
  //   width: 200,
  //   visible: false,
  //   notUserVisible: null,
  //   __typename: 'SearchColumnRow',
  // },
  {
    id: 'importRejectionComments',
    displayName: 'Import Rejection Comments',
    sortable: false,
    componentType: 'text',
    width: 200,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'itemRelationships',
    displayName: 'DA Relationship',
    sortable: false,
    componentType: 'text',
    width: null,
    visible: true,
    notUserVisible: null,
    __typename: 'SearchColumnRow',
  },
  {
    id: 'lastValidationDate',
    displayName: 'Last Validation Date',
    sortable: false,
    componentType: 'text',
    width: 200,
    visible: true,
  },
]

export const SEARCH_TEXT = {
  ERROR_TEXT: {
    DPCI_ERROR:
      'Some DPCIs are in an incompatible format. Please update the above using the hyphen or slash format. Example:123-45-6789',
    BARCODE_ERROR:
      'Some barcodes are in an incompatible format. Barcodes must be numeric and be between 8 to 18 digits long.',
    DATE_ERROR: {
      START_DATE_ERROR: 'Start date is not valid.',
      END_DATE_WITHOUT_START_DATE: 'Start date is required.',
      END_DATE_ERROR1: 'End Date is not valid',
      END_DATE_ERROR2: 'End date must be after start date.',
    },
    STYLE_NUMBER_ERROR:
      'Some style numbers are in an incompatible format. Please update the above using the correct format.',
  },
  LOV: {
    LOADING: 'Loading...',
    NO_RESULTS: 'No results found.',
  },
}

export const DQ_COLUMNS = [
  // 'Recall Indicator'
  'tcin',
  'dpci',
  'departmentId',
  'departmentName',
  'classId',
  'className',
  'merchandiseTypeName',
  'itemTypeName',
  'legacyCreateType',
  'productTitle',
  'vendorDescription',
  'vendor',
  'launchDate',
  'itemDataQualityIssues',
  'vendorDetails',
  'lastValidationDate',
]
export const INTERNAL_COLUMNS = internal_columns
export const EXTERNAL_COLUMNS = external_columns
