import MultipleFilterComponent from '../resuableFilters/mulitpleFilterComponenet'

export const WeeksDateFilter = ({
  id,
  onFilterChange,
  isFav,
  selectedValue,
  setSelectedValues,
  attributeId,
  attributeKey,
  dependentAttributeId,
  dependentAttributeKey,
  label,
  dependentLabel,
}) => {
  return (
    <MultipleFilterComponent
      filterId={id}
      isFav={isFav}
      attributeId={attributeId}
      attributeKey={attributeKey}
      dependentAttributeId={dependentAttributeId}
      dependentAttributeKey={dependentAttributeKey}
      label={label}
      dependentLabel={dependentLabel}
      onFilterChange={onFilterChange}
      selectedValue={selectedValue}
      setSelectedValues={setSelectedValues}
    />
  )
}
